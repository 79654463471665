import { VacationApplicationCategory, VacationApplicationType } from 'src/shared/types/swagger/api.dto'

export function filterVacationTypeOptions(
  vacationCategoryValue?: VacationApplicationCategory | null,
  vacationType?: VacationApplicationType | null,
): boolean {
  switch (vacationCategoryValue) {
    case VacationApplicationCategory.LABOR_LEAVE:
      return vacationType === VacationApplicationType.PLANNED || vacationType === VacationApplicationType.UNPLANNED
    case VacationApplicationCategory.SOCIAL_LEAVE:
      return (
        vacationType === VacationApplicationType.MARRIAGE_REGISTRATION ||
        vacationType === VacationApplicationType.MOTHER_BIRTH_OF_CHILD ||
        vacationType === VacationApplicationType.FATHER_BIRTH_OF_CHILD ||
        vacationType === VacationApplicationType.DEATH_OF_RELATIVE ||
        vacationType === VacationApplicationType.ILLNESS_OF_RELATIVE
      )
    case VacationApplicationCategory.PARENTAL_LEAVE:
      return (
        vacationType === VacationApplicationType.MOTHER_BIRTH_OF_CHILD ||
        vacationType === VacationApplicationType.FATHER_BIRTH_OF_CHILD ||
        vacationType === VacationApplicationType.ADOPTION_OF_CHILD ||
        vacationType === VacationApplicationType.CHILD_CARE
      )
    default:
      return false
  }
}
