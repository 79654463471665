/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AllowedValues */
export interface AllowedValues {
  /** Requirements */
  requirements?: AllowedValuesRequirements[] | null
  /** Value */
  value: string
}

/** AllowedValuesRequirements */
export interface AllowedValuesRequirements {
  /** Name */
  name: string
  /** Value */
  value: string
}

/** ApplicationAuthor */
export interface ApplicationAuthor {
  /** Company Id */
  companyId: number
  /** Department Id */
  departmentId: number
  /** Id */
  id: number
  /** Name */
  name: string
  /** Position Id */
  positionId: number
}

/** ApplicationChangeRecord */
export interface ApplicationChangeRecord {
  /** Comment */
  comment?: string | null
  /**
   * Created
   * @format date-time
   */
  created: string
  /** Id */
  id: number
  /** Role */
  role: string | null
  /** Updated By */
  updatedBy: number
  updatedStatus: Status
  /** Vacation Application Id */
  vacationApplicationId: number
}

/** ApplicationChangeRecordCreate */
export interface ApplicationChangeRecordCreate {
  /** Comment */
  comment?: string | null
  updatedStatus: Status
  /** Vacation Application Id */
  vacationApplicationId: number
}

/** ApplicationField */
export interface ApplicationField {
  /** Allowed Values */
  allowedValues?: AllowedValues[] | null
  /** Fields */
  fields?: ApplicationField[] | null
  /** Name */
  name: string
  /** Type */
  type: string
}

/** ApplicationStructure */
export interface ApplicationStructure {
  /** Name */
  name: string
  /** Value */
  value: object
}

/** ApprovalProcess */
export interface ApprovalProcess {
  /** Approver Position Ids */
  approverPositionIds: number[]
  /** Can Decline */
  canDecline: boolean
  documents: DocumentsRequired
  /** Id */
  id: number
  /** Name */
  name: string
  /** Step */
  step: number
}

/** ApprovalProcessCreate */
export interface ApprovalProcessCreate {
  /** Approver Position Ids */
  approverPositionIds: number[]
  /** Can Decline */
  canDecline: boolean
  documents: DocumentsRequired
  /** Name */
  name: string
  /** Step */
  step: number
}

/** Benefit */
export interface Benefit {
  /** Approval Process */
  approvalProcess: ApprovalProcess[]
  /** Benefit Change Record */
  benefitChangeRecord: BenefitChangeRecord[]
  /** Benefit Type Fields */
  benefitTypeFields?: BenefitField[] | null
  /**
   * Created
   * @format date
   */
  created: string
  /** Description */
  description: string
  /** Id */
  id: number
  /** Instruction */
  instruction: string
  /** Name */
  name: string
  /** Photo Id */
  photoId: number
  requirements: BenefitRequirement
  status: BenefitStatus
  /** Type Id */
  typeId: number
}

/** BenefitApplication */
export interface BenefitApplication {
  /** Application Structure */
  applicationStructure?: ApplicationStructure[] | null
  author: ApplicationAuthor
  /** Benefit Application Change Record */
  benefitApplicationChangeRecord: BenefitApplicationChangeRecord[]
  /** Benefit Id */
  benefitId: number
  /**
   * Created
   * @format date
   */
  created: string
  /** Current Approval Step Id */
  currentApprovalStepId: number
  /** Documents Ids */
  documentsIds: number[]
  /** Explanation */
  explanation: string
  /** Id */
  id: number
  status: BenefitApplicationStatus
}

/** BenefitApplicationChangeRecord */
export interface BenefitApplicationChangeRecord {
  /** Benefit Application Id */
  benefitApplicationId: number
  /** Comment */
  comment?: string | null
  /**
   * Updated
   * @format date
   */
  updated: string
  updatedBy: UpdatedBy
  updatedStatus: BenefitApplicationStatus
}

/** BenefitApplicationChangeRecordCreate */
export interface BenefitApplicationChangeRecordCreate {
  /** Benefit Application Id */
  benefitApplicationId: number
  /** Comment */
  comment?: string | null
  /** Updated By */
  updatedBy: number
  updatedStatus: BenefitApplicationStatus
}

/** BenefitApplicationCreate */
export interface BenefitApplicationCreate {
  /** Application Structure */
  applicationStructure?: ApplicationStructure[] | null
  /** Author Id */
  authorId: number
  /** Benefit Id */
  benefitId: number
  /**
   * Created
   * @format date
   */
  created: string
  /** Documents Ids */
  documentsIds: number[]
  /** Explanation */
  explanation: string
  /** Id */
  id: number
  /** Status */
  status: string
}

/** BenefitApplicationStatus */
export enum BenefitApplicationStatus {
  SENT_FOR_REVISION = 'SENT_FOR_REVISION',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  IN_REVIEW = 'IN_REVIEW',
}

/** BenefitChangeRecord */
export interface BenefitChangeRecord {
  /** Id */
  id: number
  /**
   * Updated
   * @format date
   */
  updated: string
  updatedBy: UpdatedBy
}

/** BenefitCreate */
export interface BenefitCreate {
  /** Approval Process */
  approvalProcess: ApprovalProcessCreate[]
  /** Benefit Fields */
  benefitFields?: BenefitField[] | null
  /** Description */
  description: string
  /** Instruction */
  instruction: string
  /** Name */
  name: string
  /** Photo Id */
  photoId: number
  requirements: BenefitRequirementCreate
  status: BenefitStatus
  /** Type Id */
  typeId: number
}

/** BenefitField */
export interface BenefitField {
  /** Name */
  name: string
  /** Type */
  type: string
  /** Value */
  value: string
}

/** BenefitRequirement */
export interface BenefitRequirement {
  application: BenefitRequirementApplication
  availability: BenefitRequirementAvailability
  /** Id */
  id: number
}

/** BenefitRequirementApplication */
export interface BenefitRequirementApplication {
  documents: DocumentsRequiredApplication
  /** Needs Explanation */
  needsExplanation: boolean
}

/** BenefitRequirementAvailability */
export interface BenefitRequirementAvailability {
  /** Available */
  available: boolean
  /** City Ids */
  cityIds: number[]
  /** Company Ids */
  companyIds: number[]
  /** Department Ids */
  departmentIds: number[]
  /** Min Months Employed */
  minMonthsEmployed: number
  /** Position Ids */
  positionIds: number[]
}

/** BenefitRequirementCreate */
export interface BenefitRequirementCreate {
  application: BenefitRequirementApplication
  availability: BenefitRequirementAvailability
}

/** BenefitStatus */
export enum BenefitStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/** BenefitType */
export interface BenefitType {
  /** Application Structure */
  applicationStructure?: ApplicationField[] | null
  /** Benefit Fields */
  benefitFields?: BenefitTypeField[] | null
  /** Id */
  id: number
  /** Name */
  name: string
}

/** BenefitTypeField */
export interface BenefitTypeField {
  /** Name */
  name: string
  /** Type */
  type: string
}

/** Body_create_document_v1_vacation_applications_document_post */
export interface BodyCreateDocumentV1VacationApplicationsDocumentPost {
  /**
   * File
   * @format binary
   */
  file: File
}

/** ChangeRecord */
export interface ChangeRecord {
  /** Comment */
  comment?: string | null
  /** Id */
  id: number
  /** Updated By */
  updatedBy: number
  updatedStatus: Status
  /** Vacation Schedule Id */
  vacationScheduleId: number
}

/** ChangeRecordUpdatedBy */
export interface ChangeRecordUpdatedBy {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** ChangeRecordWithUpdatedBy */
export interface ChangeRecordWithUpdatedBy {
  /** Comment */
  comment?: string | null
  /** Id */
  id: number
  updatedBy: ChangeRecordUpdatedBy
  updatedStatus: Status
  /** Vacation Schedule Id */
  vacationScheduleId: number
}

/** ChangeRecordsCreate */
export interface ChangeRecordsCreate {
  /** Comment */
  comment?: string | null
  updatedStatus: Status
  /** Vacation Schedule Ids */
  vacationScheduleIds: number[]
}

/** CitySchema */
export interface CitySchema {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** CompanySchema */
export interface CompanySchema {
  /**
   * Id
   * @example "1"
   */
  id: number
  /**
   * Name
   * @example "MyCar"
   */
  name: string
}

/** DaysLeft */
export interface DaysLeft {
  /**
   * Date
   * @format date
   */
  date: string
  /** Days Left */
  daysLeft: number
}

/** DepartmentSchema */
export interface DepartmentSchema {
  /**
   * Id
   * @example "12"
   */
  id: number
  /**
   * Name
   * @example "Отдел продаж"
   */
  name: string
  /**
   * Parent Department
   * @example "13"
   */
  parentDepartment: number | null
  /**
   * Supervisor Id
   * @example "55"
   */
  supervisorId: number
}

/** Document */
export interface Document {
  /** S3 Link */
  s3Link: string
  /** Content Type */
  contentType: string
  /** Document Type */
  documentType: string
  /** Id */
  id: number
  /** Vacation Application Id */
  vacationApplicationId?: number | null
}

/** DocumentsRequired */
export interface DocumentsRequired {
  /** Required */
  required: boolean
}

/** DocumentsRequiredApplication */
export interface DocumentsRequiredApplication {
  /** Example Ids */
  exampleIds: number[]
  /** Required */
  required: boolean
}

/** EmployeeIdRoleSchema */
export interface EmployeeIdRoleSchema {
  /**
   * Id
   * @example "123"
   */
  id: number
  role: Role
}

/** EmployeeIdSchema */
export interface EmployeeIdSchema {
  /**
   * Id
   * @example "123"
   */
  id: number
}

/** EmployeeSchema */
export interface EmployeeSchema {
  /**
   * Birth Date
   * @format date
   * @example "1990-01-01"
   */
  birthDate: string
  /**
   * Company Id
   * @example "2"
   */
  companyId: number
  /**
   * Created
   * @format date-time
   * @example "2023-08-01T00:00:00"
   */
  created?: string
  /**
   * Department Id
   * @example "3"
   */
  departmentId: number
  /**
   * Email
   * @example "alexandr90@test.com"
   */
  email: string
  /**
   * Employment Date
   * @format date
   * @example "2020-01-01"
   */
  employmentDate: string
  /**
   * First Name
   * @example "Александр"
   */
  firstName: string
  /**
   * Id
   * @example "15"
   */
  id: number
  /**
   * Inn
   * @example 123456789012
   */
  inn: number
  /**
   * Is Active
   * @default true
   * @example true
   */
  isActive?: boolean
  /**
   * Last Name
   * @example "Иванов"
   */
  lastName: string
  /**
   * Middle Name
   * @example "Александрович"
   */
  middleName: string
  /**
   * Position Id
   * @example "2"
   */
  positionId: number
}

/** EmployeeVacationScheduleWithChangeRecord */
export interface EmployeeVacationScheduleWithChangeRecord {
  /**
   * Change Record
   * @default []
   */
  changeRecord?: ChangeRecordWithUpdatedBy[]
  /** Days To Schedule */
  daysToSchedule: number
  /** Department */
  department: string
  /** Employee Id */
  employeeId: number
  /** Employee Name */
  employeeName: string
  /** Id */
  id: number
  /** Planned Days */
  plannedDays: number
  /** Position */
  position: string
  status: Status
  /** Time Period Number */
  timePeriodNumber: number
  /**
   * Time Periods
   * @default []
   */
  timePeriods?: VacationTimePeriod[]
  /** Unplanned Days */
  unplannedDays: number
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[]
}

/** PositionSchema */
export interface PositionSchema {
  /**
   * Id
   * @example "1"
   */
  id: number
  /**
   * Name
   * @example "Менеджер отдела"
   */
  name: string
}

/** Role */
export enum Role {
  Manager = 'manager',
  Employee = 'employee',
  Hr = 'hr',
  Hrd = 'hrd',
}

/** Status */
export enum Status {
  DRAFT = 'DRAFT',
  PROCESSING_BY_MANAGER = 'PROCESSING_BY_MANAGER',
  APPROVED_BY_MANAGER = 'APPROVED_BY_MANAGER',
  PROCESSING_BY_HR = 'PROCESSING_BY_HR',
  SENT_FOR_REVISION = 'SENT_FOR_REVISION',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

/** UpdatedBy */
export interface UpdatedBy {
  /** Id */
  id: number
  /** Name */
  name: string
}

/** VacationApplication */
export interface VacationApplication {
  author: VacationApplicationAuthor
  category: VacationApplicationCategory
  /**
   * Created
   * @format date-time
   */
  created: string
  /** Description */
  description?: string | null
  /**
   * Documents
   * @default []
   */
  documents?: Document[] | null
  /**
   * End Date
   * @format date
   */
  endDate: string
  /** Id */
  id: number
  reviewers: VacationApplicationReviewers | null
  /**
   * Start Date
   * @format date
   */
  startDate: string
  status: Status
  /** Substitute User Id */
  substituteUserId?: number | null
  type?: VacationApplicationType | null
  /**
   * Vacation Application Change Record
   * @default []
   */
  vacationApplicationChangeRecord?: ApplicationChangeRecord[] | null
}

/** VacationApplicationAuthor */
export interface VacationApplicationAuthor {
  /** Department */
  department: string
  /** Id */
  id: number
  /** Name */
  name: string
  /** Position */
  position: string
}

/** VacationApplicationCategory */
export enum VacationApplicationCategory {
  LABOR_LEAVE = 'LABOR_LEAVE',
  STUDY_LEAVE = 'STUDY_LEAVE',
  SOCIAL_LEAVE = 'SOCIAL_LEAVE',
  PARENTAL_LEAVE = 'PARENTAL_LEAVE',
  UNPAID = 'UNPAID',
}

/** VacationApplicationCreate */
export interface VacationApplicationCreate {
  category: VacationApplicationCategory
  /** Description */
  description?: string | null
  /** Document Ids */
  documentIds?: number[] | null
  /**
   * End Date
   * @format date
   */
  endDate: string
  /**
   * Start Date
   * @format date
   */
  startDate: string
  /** Substitute User Id */
  substituteUserId?: number | null
  type?: VacationApplicationType | null
}

/** VacationApplicationErrorTypes */
export enum VacationApplicationErrorTypes {
  VACATION_APPLICATION_NOT_FOUND_BY_ID = 'VACATION_APPLICATION_NOT_FOUND_BY_ID',
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
  REVIEWERS_NOT_FOUND = 'REVIEWERS_NOT_FOUND',
  DOCUMENT_NOT_FOUND_BY_ID = 'DOCUMENT_NOT_FOUND_BY_ID',
}

/** VacationApplicationExceptionSchema */
export interface VacationApplicationExceptionSchema {
  detail: VacationApplicationValidationErrorTypes
}

/** VacationApplicationResponse */
export interface VacationApplicationResponse {
  /** Application Change Records */
  applicationChangeRecords: ApplicationChangeRecord[]
  /** Author Id */
  authorId: number
  category: VacationApplicationCategory
  /**
   * Created
   * @format date-time
   */
  created: string
  /** Description */
  description?: string | null
  /**
   * Documents
   * @default []
   */
  documents?: Document[] | null
  /**
   * End Date
   * @format date
   */
  endDate: string
  /** Id */
  id: number
  /**
   * Start Date
   * @format date
   */
  startDate: string
  status: Status
  /** Substitute User Id */
  substituteUserId?: number | null
  type?: VacationApplicationType | null
  /** Vacation Application Reviewers */
  vacationApplicationReviewers: VacationApplicationReviewers[]
}

/** VacationApplicationReviewers */
export interface VacationApplicationReviewers {
  /** Hr Id */
  hrId?: number | null
  /** Id */
  id: number
  /** Manager Id */
  managerId?: number | null
  /** Vacation Application Id */
  vacationApplicationId: number
}

/** VacationApplicationReviewersBase */
export interface VacationApplicationReviewersBase {
  /** Hr Id */
  hrId?: number | null
  /** Manager Id */
  managerId?: number | null
}

/** VacationApplicationType */
export enum VacationApplicationType {
  PLANNED = 'PLANNED',
  UNPLANNED = 'UNPLANNED',
  MARRIAGE_REGISTRATION = 'MARRIAGE_REGISTRATION',
  FATHER_BIRTH_OF_CHILD = 'FATHER_BIRTH_OF_CHILD',
  MOTHER_BIRTH_OF_CHILD = 'MOTHER_BIRTH_OF_CHILD',
  DEATH_OF_RELATIVE = 'DEATH_OF_RELATIVE',
  ILLNESS_OF_RELATIVE = 'ILLNESS_OF_RELATIVE',
  ADOPTION_OF_CHILD = 'ADOPTION_OF_CHILD',
  CHILD_CARE = 'CHILD_CARE',
}

/** VacationApplicationUpdate */
export interface VacationApplicationUpdate {
  category: VacationApplicationCategory
  /** Description */
  description?: string | null
  /** Document Ids */
  documentIds?: number[] | null
  /**
   * End Date
   * @format date
   */
  endDate: string
  reviewers?: VacationApplicationReviewersBase | null
  /**
   * Start Date
   * @format date
   */
  startDate: string
  /** Substitute User Id */
  substituteUserId?: number | null
  type?: VacationApplicationType | null
}

/** VacationApplicationValidationErrorTypes */
export enum VacationApplicationValidationErrorTypes {
  SHOULD_NOT_HAVE_TYPE = 'SHOULD_NOT_HAVE_TYPE',
  INVALID_TYPE = 'INVALID_TYPE',
  DOCUMENTS_NOT_ATTACHED = 'DOCUMENTS_NOT_ATTACHED',
  NO_DESCRIPTION = 'NO_DESCRIPTION',
  TOO_MUCH_DAYS = 'TOO_MUCH_DAYS',
  WRONG_STATUS = 'WRONG_STATUS',
  NOT_ALLOWED_CONTENT_TYPE = 'NOT_ALLOWED_CONTENT_TYPE',
  INVALID_DOCUMENT_ID = 'INVALID_DOCUMENT_ID',
}

/** VacationSchedule */
export interface VacationSchedule {
  /**
   * Change Record
   * @default []
   */
  changeRecord?: ChangeRecord[]
  /** Days To Schedule */
  daysToSchedule: number
  /** Employee Id */
  employeeId: number
  /** Id */
  id: number
  status: Status
  /**
   * Time Periods
   * @default []
   */
  timePeriods?: VacationTimePeriod[]
}

/** VacationScheduleErrorTypes */
export enum VacationScheduleErrorTypes {
  VACATION_NOT_FOUND_BY_ID = 'VACATION_NOT_FOUND_BY_ID',
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
}

/** VacationScheduleExceptionDictSchema */
export interface VacationScheduleExceptionDictSchema {
  /** Id */
  id: VacationScheduleErrorTypes | VacationScheduleValidationErrorTypes
}

/** VacationScheduleExceptionNotFoundSchema */
export interface VacationScheduleExceptionNotFoundSchema {
  detail: VacationScheduleErrorTypes
}

/** VacationScheduleExceptionsArraySchema */
export interface VacationScheduleExceptionsArraySchema {
  /** Detail */
  detail: (VacationScheduleErrorTypes | VacationScheduleValidationErrorTypes)[]
}

/** VacationScheduleExceptionsDictArraySchema */
export interface VacationScheduleExceptionsDictArraySchema {
  /** Detail */
  detail: VacationScheduleExceptionDictSchema[]
}

/** VacationScheduleValidationErrorTypes */
export enum VacationScheduleValidationErrorTypes {
  NOTEMPLOYEDFOR6MONTHS = 'NOT_EMPLOYED_FOR_6_MONTHS',
  VACATION_SCHEDULE_ALREADY_EXISTS = 'VACATION_SCHEDULE_ALREADY_EXISTS',
  WRONG_STATUS = 'WRONG_STATUS',
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
  NO_PERIODS_PROVIDED = 'NO_PERIODS_PROVIDED',
  NOT_PLANNED_FOR_NEXT_YEAR = 'NOT_PLANNED_FOR_NEXT_YEAR',
  DAYS_BETWEEN_PERIODS = 'DAYS_BETWEEN_PERIODS',
  ONEPERIODMIN14DAYS = 'ONE_PERIOD_MIN_14_DAYS',
  MORE_DAYS_THAN_ALLOWED = 'MORE_DAYS_THAN_ALLOWED',
  DAYS_PLANNED = 'DAYS_PLANNED',
}

/** VacationSchedulesAggregation */
export interface VacationSchedulesAggregation {
  /** Employees Number */
  employeesNumber: number
  totalStatus: Status
}

/** VacationSchedulesWithAggregation */
export interface VacationSchedulesWithAggregation {
  aggregation?: VacationSchedulesAggregation | null
  /** Pages */
  pages: number
  /**
   * Vacation Schedules
   * @default []
   */
  vacationSchedules?: EmployeeVacationScheduleWithChangeRecord[]
}

/** VacationTimePeriod */
export interface VacationTimePeriod {
  /**
   * End Date
   * @format date
   */
  endDate: string
  /** Id */
  id: number
  /**
   * Start Date
   * @format date
   */
  startDate: string
  /** Vacation Schedule Id */
  vacationScheduleId: number
}

/** VacationTimePeriodCreate */
export interface VacationTimePeriodCreate {
  /**
   * End Date
   * @format date
   */
  endDate: string
  /**
   * Start Date
   * @format date
   */
  startDate: string
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[]
  /** Message */
  msg: string
  /** Error Type */
  type: string
}
