import { Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps } from 'react-router-dom'

export type BreadcrumbLinkProps = Pick<NavLinkProps, 'to' | 'end'> & {
  name?: string
}

export const BreadcrumbLink = ({ name, to, end = true }: BreadcrumbLinkProps) => {
  const { t } = useTranslation()

  return name ? (
    <NavLink
      to={to}
      end={end}
      style={({ isActive }: { isActive: boolean }) => ({
        pointerEvents: isActive ? 'none' : 'all',
        textDecoration: 'none',
      })}
    >
      {({ isActive }) => (
        <Text fw={500} size="sm" c={isActive ? 'aceent' : 'text-grey-1'}>
          {t(name)}
        </Text>
      )}
    </NavLink>
  ) : null
}
