import { CompanySchema } from 'src/shared/types/swagger/api.dto'

// TODO: Удалить мок после интеграции с эндпоинтом /positions
export const positionListMock: CompanySchema[] = [
  {
    id: 1,
    name: 'Frontend разработчик',
  },
  {
    id: 2,
    name: 'Backend разработчик',
  },
  {
    id: 3,
    name: 'Fullstack разработчик',
  },
  {
    id: 4,
    name: 'DevOps инженер',
  },
  {
    id: 5,
    name: 'UX/UI дизайнер',
  },
  {
    id: 6,
    name: 'QA инженер',
  },
  {
    id: 7,
    name: 'Системный аналитик',
  },
  {
    id: 8,
    name: 'Руководитель проектов',
  },
  {
    id: 9,
    name: 'Бизнес-аналитик',
  },
  {
    id: 10,
    name: 'iOS разработчик',
  },
  {
    id: 11,
    name: 'Android разработчик',
  },
]
