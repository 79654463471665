import { Navigate, Outlet, useParams } from 'react-router-dom'

import { PATHS } from 'src/shared/constants'

export function RootHeadApplicationDetailPage() {
  const { applicationId } = useParams<'applicationId'>()

  if (!applicationId) {
    return <Navigate to={PATHS.head.index} replace />
  }

  return <Outlet />
}
