import axios from 'axios'
import qs from 'qs'

import { STORAGE_KEYS } from '../constants'
import { camelizeKeys, cleanQueryParams, decamelizeKeys } from '../utils'

export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URI,
  formSerializer: {
    indexes: null,
  },
  paramsSerializer: (params) => qs.stringify(cleanQueryParams(params), { arrayFormat: 'repeat', skipNulls: true }),
  withCredentials: true,
})

axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config }

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params)
    }

    // для 'multipart/form-data' не будет decamelizeKeys
    if (config.data && newConfig.headers['Content-Type'] !== 'multipart/form-data') {
      newConfig.data = decamelizeKeys(config.data)
    }

    const token = localStorage.getItem(STORAGE_KEYS.accessToken)
    if (token) {
      newConfig.headers['Authorization'] = `Bearer ${token}`
    }

    return newConfig
  },
  (error) => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data)
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)
