import { QueryClient, useMutation, useQueries, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Params } from 'react-router-dom'

import { axiosInstance } from '../axios'
import { cityListMock, positionListMock } from '../mocks'
import { ApplicationFilters, BenefitsParams, CompaniesAggregations, YearsAggregations } from '../types'
import {
  ApplicationChangeRecordCreate,
  Benefit,
  BenefitCreate,
  BenefitType,
  ChangeRecord,
  ChangeRecordsCreate,
  CompanySchema,
  DaysLeft,
  DepartmentSchema,
  Document,
  EmployeeIdRoleSchema,
  EmployeeIdSchema,
  EmployeeSchema,
  EmployeeVacationScheduleWithChangeRecord,
  Status,
  VacationApplication,
  VacationApplicationCreate,
  VacationApplicationExceptionSchema,
  VacationApplicationResponse,
  VacationSchedule,
  VacationScheduleExceptionsArraySchema,
  VacationScheduleExceptionsDictArraySchema,
  VacationSchedulesWithAggregation,
  VacationTimePeriodCreate,
} from '../types/swagger/api.dto'
import { getAttachmentNameFromHeader } from '../utils/get-attachment-name-from-header/get-attachment-name-from-header'

const URI = {
  vacations: '/vacations/',
  vacationsSchedules: '/vacation-schedules',
  process: '/vacation-schedules/process',
  years: '/vacation-schedules/years',

  applications: '/vacation-applications',
  applicationsDetail: (applicationId: string) => `/vacation-applications/${applicationId}`,
  applicationsDoc: `/vacation-applications/document`,
  applicationsDocDetail: (docId: number) => `/vacation-applications/document/${docId}`,
  applicationProcess: 'vacation-applications/process',

  daysLeft: '/vacations/days_left',

  departments: '/departments',

  getToken: '/get_token',

  me: '/me',
  employees: '/employees',

  benefits: '/benefits',
  createBenefit: '/benefits/benefits',
  benefitsTypes: '/benefits/types',

  companies: '/companies',
  positions: '/positions',
  cities: '/cities',
}

export function useApplications(filters?: Partial<ApplicationFilters>) {
  return useQuery<Array<VacationApplication>>({
    queryKey: ['applications', filters],
    queryFn: () => axiosInstance.get(URI.applications, { params: filters }).then(({ data }) => data),
  })
}

export function useApplicationsDetail(applicationId?: string | null) {
  return useQuery<VacationApplication>({
    queryKey: ['applications', applicationId],
    queryFn: () => axiosInstance.get(URI.applicationsDetail(applicationId!)).then(({ data }) => data),
    enabled: !!applicationId,
  })
}

export type DepartmentsParams = Partial<{
  companyId: number
  departmentId: number
}>

export function useDepartments(params?: DepartmentsParams) {
  return useQuery({
    queryKey: ['departments', params],
    queryFn: () => axiosInstance.get<DepartmentSchema[]>(URI.departments, { params }).then(({ data }) => data),
  })
}

export function useYears() {
  return useQuery({
    queryKey: ['years'],
    queryFn: () => axiosInstance.get<number[]>(URI.years).then(({ data }) => data),
  })
}

export type VacationSchedulesParams = Partial<{
  employeeId: number
  status: Status
  departmentId: number
  companyId: number
  year: number
  fullName: string
  limit: number
  offset: number
}>

export function useVacationSchedules(params?: VacationSchedulesParams, enabled?: boolean) {
  return useQuery({
    queryKey: ['vacationSchedules', params],
    queryFn: () =>
      axiosInstance.get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params }).then(({ data }) => data),
    enabled,
  })
}

export function useVacationSchedulesCreate() {
  return useMutation<VacationSchedule, AxiosError<VacationScheduleExceptionsArraySchema>, VacationTimePeriodCreate[]>({
    mutationFn: (payload) => axiosInstance.post(URI.vacationsSchedules, payload).then(({ data }) => data),
  })
}

export function useVacationSchedulesUpdate() {
  return useMutation<
    VacationSchedule,
    AxiosError<VacationScheduleExceptionsArraySchema>,
    { timePeriods: VacationTimePeriodCreate[]; vacationScheduleId: number }
  >({
    mutationFn: (payload) =>
      axiosInstance
        .put(`${URI.vacationsSchedules}/${payload.vacationScheduleId}`, payload.timePeriods)
        .then(({ data }) => data),
  })
}

export const getEmployeeVacationSchedule = (vacationScheduleId: string) => ({
  queryKey: ['employeeVacationSchedule', vacationScheduleId],
  queryFn: () =>
    axiosInstance
      .get<EmployeeVacationScheduleWithChangeRecord>(`${URI.vacationsSchedules}/${vacationScheduleId}`)
      .then(({ data }) => data),
})

export function selectedEmployeeScheduleLoader(queryClient: QueryClient) {
  return async ({ params }: { params: Params<'vacationScheduleId'> }) => {
    const data = await queryClient.ensureQueryData(getEmployeeVacationSchedule(params?.vacationScheduleId as string))
    return data
  }
}

export function useEmployeeVacationSchedule(vacationScheduleId: string) {
  return useQuery(getEmployeeVacationSchedule(vacationScheduleId))
}

export function useGetToken() {
  return useMutation({
    mutationFn: (payload: EmployeeIdSchema) =>
      axiosInstance.post<{ token: string }>(URI.getToken, payload).then(({ data }) => data),
  })
}

export function useGetMe() {
  return useQuery({
    queryKey: ['me'],
    queryFn: () => axiosInstance.get<EmployeeIdRoleSchema>(URI.me).then((res) => res.data),
    // запрашиваем один раз
    staleTime: Infinity,
    throwOnError: true,
  })
}

export function useUpdateProcess() {
  return useMutation<ChangeRecord[], AxiosError<VacationScheduleExceptionsDictArraySchema>, ChangeRecordsCreate>({
    mutationFn: (payload) => axiosInstance.put(URI.process, payload).then(({ data }) => data),
  })
}

export function useCompanies(companyId?: number) {
  return useQuery({
    queryKey: ['companies', companyId],
    queryFn: () =>
      axiosInstance
        .get<CompanySchema[]>(URI.companies, {
          params: {
            companyId,
          },
        })
        .then((res) => res.data),
  })
}

export function useCompaniesAggregations(params?: CompanySchema[]) {
  return useQueries({
    queries:
      params?.map(({ id, name }) => ({
        queryKey: ['vacationSchedules', { companyId: id }],
        queryFn: () =>
          axiosInstance
            .get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params: { companyId: id } })
            .then(({ data }) => ({ ...data.aggregation, name, id })),
      })) ?? [],
    combine: (results) => ({
      data: results.filter((res) => res.data).map(({ data }) => data as CompaniesAggregations),
      isLoading: results.some((result) => result.isLoading),
      isSuccess: results.length > 0 ? results.every((result) => result.isSuccess) : true,
    }),
  })
}

export function useYearsAggregations(years?: number[]) {
  return useQueries({
    queries:
      years?.map((year) => ({
        queryKey: ['vacationSchedules', { year }],
        queryFn: () =>
          axiosInstance
            .get<VacationSchedulesWithAggregation>(URI.vacationsSchedules, { params: { year } })
            .then(({ data }) => ({ ...data.aggregation, year })),
      })) ?? [],
    combine: (results) => ({
      data: results.filter((res) => res.data).map(({ data }) => data as YearsAggregations),
      isLoading: results.some((result) => result.isLoading),
      isSuccess: results.length > 0 ? results.every((result) => result.isSuccess) : true,
    }),
  })
}

type EmployeesFilters = Partial<{
  employeeId: number | null
  fullName: string
  departmentId: number
  companyId: number
}>

export function useEmployees(filters: EmployeesFilters, enabled: boolean = true) {
  return useQuery<EmployeeSchema[]>({
    queryKey: ['employees', filters],
    queryFn: () => axiosInstance.get(URI.employees, { params: filters }).then(({ data }) => data),
    enabled,
  })
}

export function useDaysLeft(employeeId?: number) {
  return useQuery({
    queryKey: ['days-left', employeeId],
    queryFn: () => axiosInstance.get<DaysLeft>(URI.daysLeft, { params: { employeeId } }).then(({ data }) => data),
    enabled: employeeId !== undefined,
  })
}

export function useApplicationCreate() {
  return useMutation<unknown, AxiosError<VacationApplicationExceptionSchema>, VacationApplicationCreate>({
    mutationFn: (payload) => axiosInstance.post(URI.applications, payload).then(({ data }) => data),
  })
}

export function useApplicationUpdate() {
  return useMutation<
    unknown,
    AxiosError<VacationApplicationExceptionSchema>,
    VacationApplicationCreate & { applicationId: string }
  >({
    mutationFn: (payload) => {
      const { applicationId, ...rest } = payload

      return axiosInstance.put(URI.applicationsDetail(applicationId), rest).then(({ data }) => data)
    },
  })
}

export function useDocuments(documentIds: Document['id'][] | null) {
  return useQueries({
    queries: documentIds
      ? documentIds.map((docId) => ({
          queryKey: ['document', docId],
          queryFn: () =>
            axiosInstance
              .get<string>(URI.applicationsDocDetail(docId), {
                responseType: 'blob',
              })
              .then(
                (res) =>
                  new File([res.data], getAttachmentNameFromHeader(res.headers), {
                    type: res.headers['content-type'] as string,
                  }),
              ),
          // документы перезапрашивать нет смысла
          staleTime: Infinity,
        }))
      : [],
    combine: (results) => ({
      data: results.map((result) => result.data),
      isPending: results.map((result) => result.isPending),
    }),
  })
}

export function useApplicationDocsUpload() {
  return useMutation<Document['id'][], unknown, { fileList: FileList | null; vacationApplicationId?: string }>({
    mutationFn: (payload) =>
      Promise.all<Document>(
        Array.from(payload.fileList ?? []).map((file) => {
          const formData = new FormData()
          formData.append('file', file)

          return axiosInstance
            .post(URI.applicationsDoc, formData, {
              params: {
                // отправляем дефолтный
                document_type: 'application-file',
                vacationApplicationId: payload.vacationApplicationId,
              },
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => res.data)
        }),
      ).then((data) => data.map((v) => v.id)),
  })
}

export function useBenefits(params?: BenefitsParams) {
  return useQuery({
    queryKey: ['benefits', params],
    queryFn: () => axiosInstance.get<Benefit[]>(URI.benefits, { params }).then(({ data }) => data),
  })
}

export function useBenefitTypes() {
  return useQuery<BenefitType[]>({
    queryKey: ['benefitTypes'],
    queryFn: () => axiosInstance.get<BenefitType[]>(URI.benefitsTypes).then(({ data }) => data),
  })
}

// TODO: Актуализировать generic тип после добавления ручки /positions со стороны бекенда
export function usePositions() {
  return useQuery({
    queryKey: ['positions'],
    queryFn: () => new Promise<CompanySchema[]>((resolve) => resolve(positionListMock)),
  })
}

// TODO: Актуализировать generic тип после добавления ручки /cities со стороны бекенда
export function useCities() {
  return useQuery({
    queryKey: ['cities'],
    queryFn: () => new Promise<CompanySchema[]>((resolve) => resolve(cityListMock)),
  })
}

export function useBenefitCreate() {
  return useMutation<unknown, AxiosError<unknown>, BenefitCreate>({
    mutationFn: (payload) => axiosInstance.post(URI.createBenefit, payload).then(({ data }) => data),
  })
}

export function useApplicationProcess() {
  return useMutation<VacationApplicationResponse, unknown, ApplicationChangeRecordCreate>({
    mutationFn: (payload) => axiosInstance.put(URI.applicationProcess, payload).then((res) => res.data),
  })
}
