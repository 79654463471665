import { MantineColor, useMantineTheme } from '@mantine/core'

import { BenefitStatus, Status } from 'src/shared/types/swagger/api.dto'

export function useBadgeColor(status?: Status): MantineColor | undefined {
  const theme = useMantineTheme()

  if (!status) return undefined

  switch (status) {
    case Status.ACCEPTED:
      return theme.colors.green[0]
    case Status.DECLINED:
      return theme.colors.red[0]
    default:
      return theme.colors.orange[0]
  }
}

export function useBenefitBadgeColor(status?: BenefitStatus): MantineColor | undefined {
  const theme = useMantineTheme()

  if (!status) return undefined

  switch (status) {
    case BenefitStatus.ACTIVE:
      return theme.colors.green[0]
    case BenefitStatus.INACTIVE:
      return theme.colors.light[6]
  }
}
