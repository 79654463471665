import { createContext } from 'react'

import { EmployeeIdRoleSchema } from 'src/shared/types/swagger/api.dto'

export const UserContext = createContext<{
  userData: EmployeeIdRoleSchema | null
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
}>({
  userData: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
})
