import { CompanySchema } from 'src/shared/types/swagger/api.dto'

// TODO: Удалить мок после интеграции с эндпоинтом /city
export const cityListMock: CompanySchema[] = [
  {
    id: 1,
    name: 'Алматы',
  },
  {
    id: 2,
    name: 'Астана',
  },
  {
    id: 3,
    name: 'Шымкент',
  },
  {
    id: 4,
    name: 'Караганда',
  },
  {
    id: 5,
    name: 'Актобе',
  },
  {
    id: 6,
    name: 'Тараз',
  },
  {
    id: 7,
    name: 'Павлодар',
  },
  {
    id: 8,
    name: 'Усть-Каменогорск',
  },
  {
    id: 9,
    name: 'Семей',
  },
  {
    id: 10,
    name: 'Атырау',
  },
  {
    id: 11,
    name: 'Костанай',
  },
  {
    id: 12,
    name: 'Кызылорда',
  },
]
