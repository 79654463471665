import { PropsWithChildren } from 'react'

import { useGetMe } from 'src/shared/api'

import { UserContext } from './context'

export function UserProvider(props: PropsWithChildren) {
  const userQuery = useGetMe()

  return (
    <UserContext.Provider
      value={{
        userData: userQuery.isSuccess ? userQuery.data : null,
        isLoading: userQuery.isLoading,
        isSuccess: userQuery.isSuccess,
        isError: userQuery.isError,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
