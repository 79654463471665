import React from 'react'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/dates/styles.css'
import '@mantine/notifications/styles.css'

import App from './app/App'

const ROOT_ID = 'root'

ReactDOM.createRoot(document.getElementById(ROOT_ID)!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
