import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { useRole } from '../lib/hooks'
import { Role } from '../lib/types'

type RoleGuardProps = {
  roles: Role[]
  fallback: ReactNode
}

export function RoleGuard({ roles, fallback }: RoleGuardProps): ReactNode {
  const { role } = useRole()

  if (roles.includes(role)) {
    return <Outlet />
  }

  return <>{fallback}</>
}
