import {
  AppShell,
  AppShellHeader,
  AppShellMain,
  Badge,
  Button,
  Card,
  colorsTuple,
  createTheme,
  Input,
  Skeleton,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { Dropzone } from '@mantine/dropzone'
import { Notifications } from '@mantine/notifications'

import badgeClasses from '../../../ui-kit/badge/ui/badge.module.css'
import btnClasses from '../../../ui-kit/button/ui/button.module.css'
import tabsClasses from '../../../ui-kit/tabs/ui/tabs.module.css'

const NOTIFICATION_AUTO_CLOSE_TIME_MS = 5000

export const theme = createTheme({
  colors: {
    // https://www.figma.com/design/kwtnUkyKUi2VlhvRFxVELY/MyStartups?node-id=264-3651&t=VN0q9hdcIxWeowNW-4

    // main colors
    light: [
      '#F5F5F5',
      '#EEEEEE',
      '#DCDCDC',
      '#CBCBCB',
      '#BABABA',
      '#A8A8A8',
      '#979797',
      '#868686',
      '#757575',
      '#636363',
      '#525252',
      '#414141',
      '#2F2F2F',
      '#1E1E1E',
    ],
    aceent: colorsTuple('#008EFF'),
    'aceent-light': colorsTuple('#008EFF26'),
    green: colorsTuple('#18BB4F'),
    yellow: colorsTuple('#FFF827'),
    red: colorsTuple('#EB0000'),
    violet: colorsTuple('#9E38FF'),
    orange: colorsTuple('#FF8718'),

    // notification colors
    'notification-blue-light': colorsTuple('#DEEFFF'),
    'notification-green-light': colorsTuple('#C7F1D8'),
    'notification-orange-light': colorsTuple('#FFF8B5'),

    // background colors
    'background-red-light': colorsTuple('#FAE3E5'),
    'background-grey-1': colorsTuple('#F2F4F8'),
    'background-grey-2': colorsTuple('#E4E6ED'),

    // text colors
    'text-grey-1': colorsTuple('#BABABA'),
    'text-grey-2': colorsTuple('#868686'),
    'text-grey-3': colorsTuple('#636363'),
    'text-grey-4': colorsTuple('#6D7384'),
  },
  white: '#FFFFFF',
  black: '#0E0E0E',
  primaryColor: 'aceent',

  // typography

  // https://www.figma.com/design/kwtnUkyKUi2VlhvRFxVELY/MyStartups?node-id=264-3060&t=VN0q9hdcIxWeowNW-4
  fontFamily: 'Inter',
  fontSizes: {
    xl: '20px', // subtitle-1
    lg: '16px', // body-1 & button-1 & subtitle-2
    md: '14px', // body-2 & button-2
    sm: '12px', // body-3 label & caption & subtitle-3
    xs: '10px', // body-4
  },
  lineHeights: {
    xl: '24px', // subtitle-1
    lg: '24px', // body-1 & button-1 & subtitle-2
    md: '19.6px', // body-2 & button-2
    sm: '15.6px', // body-3 label & caption & subtitle-3
    xs: '12px', // body-4
  },
  defaultRadius: 'md',

  // https://www.figma.com/design/kwtnUkyKUi2VlhvRFxVELY/MyStartups?node-id=264-2828&t=VN0q9hdcIxWeowNW-4
  headings: {
    fontFamily: 'Okta Neue',
    sizes: {
      h1: { fontSize: '86px', lineHeight: '111.8px', fontWeight: '400' },
      h2: { fontSize: '56px', lineHeight: '84px', fontWeight: '400' },
      h3: { fontSize: '44px', lineHeight: '48.4px', fontWeight: '400' },
      h4: { fontSize: '36px', lineHeight: '39.6px', fontWeight: '400' },
      h5: { fontSize: '28px', lineHeight: '36.4px', fontWeight: '400' },
      h6: { fontSize: '22px', lineHeight: '24.2px', fontWeight: '400' },
    },
  },
  other: {
    input: {
      md: '40px',
      lg: '56px',
    },
    form: {
      desktopWidth: '552px',
    },
    fileInputDesktopWidth: '312px',
    fileCardDesktopWidth: '246px',
    notification: {
      closeTime: `${NOTIFICATION_AUTO_CLOSE_TIME_MS}ms`,
    },
  },

  components: {
    // layout
    AppShell: AppShell.extend({
      defaultProps: {
        withBorder: false,
        header: {
          height: 64,
        },
      },
    }),
    AppShellHeader: AppShellHeader.extend({
      defaultProps: {
        py: 'xs',
      },
    }),
    AppShellMain: AppShellMain.extend({
      defaultProps: {
        bg: 'background-grey-1',
      },
    }),
    // ui-kit
    Text: Text.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
    Button: Button.extend({
      defaultProps: {
        size: 'md',
        variant: 'filled',
        tt: 'uppercase',
      },
      classNames: {
        root: btnClasses.root,
      },
    }),
    Card: Card.extend({
      defaultProps: {
        mih: 172,
        radius: 'md',
      },
    }),
    Badge: Badge.extend({
      defaultProps: {
        size: 'md',
      },
      classNames: {
        root: badgeClasses.root,
      },
    }),
    Tabs: Tabs.extend({
      classNames: {
        tab: tabsClasses.tab,
        tabLabel: tabsClasses.tabLabel,
      },
    }),
    // Input related
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        size: 'lg',
      },
    }),
    Input: Input.extend({
      vars: (theme, { size }) => ({
        wrapper: {
          '--input-height': size === 'lg' ? theme.other.input.lg : theme.other.input.md,
        },
      }),
      defaultProps: {
        size: 'md',
      },
      styles: {
        input: {
          caretColor: '#008EFF',
          minWidth: '200px',
        },
      },
    }),
    Dropzone: Dropzone.extend({
      vars: (theme) => ({
        root: {
          '--dropzone-radius': theme.radius.md,
        },
      }),
    }),
    Notifications: Notifications.extend({
      defaultProps: {
        position: 'top-right',
        autoClose: NOTIFICATION_AUTO_CLOSE_TIME_MS,
      },
    }),
    Skeleton: Skeleton.extend({
      defaultProps: {
        mih: 172,
      },
    }),
  },
})
