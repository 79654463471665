import { AppShell, Button, Container, Flex, Select, useMantineColorScheme } from '@mantine/core'
import { PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'

import { NamedAvatar } from 'src/entities/user'
import { PATHS } from 'src/shared/constants'
import { useFeatureFlag } from 'src/shared/hooks'
import { Role, useRole } from 'src/shared/providers'
import { Icon } from 'src/shared/ui-kit/icon'

export const Layout = ({ children }: PropsWithChildren): ReactElement => {
  const { toggleColorScheme, setColorScheme, colorScheme } = useMantineColorScheme()
  const { isEnabled: isDarkThemeEnabled } = useFeatureFlag('dark-theme')
  const { isEnabled: isRoleSwitcherEnabled } = useFeatureFlag('role-switcher')

  const roleList: Role[] = ['head', 'hr', 'employee']

  const [user] = useState({ name: 'Мерманова Д.Р.', email: 'mermanova97@gmail.com' })
  const { role, setRole } = useRole()
  const darkBg = 'rgb(38 38 38)'

  // TODO: после релиза удалить темную тему
  useEffect(() => {
    if (!isDarkThemeEnabled) {
      setColorScheme('light')
    }
  }, [isDarkThemeEnabled, setColorScheme])

  return (
    <AppShell>
      <AppShell.Header>
        <Container bg={colorScheme === 'dark' ? darkBg : 'white'} size="xl">
          <Flex justify="space-between" align="center">
            <Link to={PATHS.index}>
              <Icon name="logo" width={96} height={23} />
            </Link>

            {isRoleSwitcherEnabled && (
              <Select placeholder="Role" data={roleList} value={role} onChange={(role) => setRole(role as Role)} />
            )}

            <NamedAvatar name={user.name} email={user.email}></NamedAvatar>
          </Flex>
        </Container>
      </AppShell.Header>

      <AppShell.Main bg={colorScheme === 'dark' ? darkBg : 'background-grey-1'}>
        <Container size="xl" my="lg">
          {children ?? <Outlet />}
        </Container>

        {isDarkThemeEnabled && (
          <Button pos="fixed" bottom="3px" left="3px" onClick={toggleColorScheme}>
            <Icon name="moon_vs_outlined" size={16}></Icon>
          </Button>
        )}
      </AppShell.Main>
    </AppShell>
  )
}
