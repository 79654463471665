import { Textarea, TextareaProps } from '@mantine/core'
import { forwardRef, useState } from 'react'

import classes from './textarea-with-floated-label.module.css'

type TextareaWithFloatedLabelProps = Pick<
  TextareaProps,
  'label' | 'value' | 'onChange' | 'required' | 'disabled' | 'error'
>

export const TextareaWithFloatedLabel = forwardRef<HTMLTextAreaElement, TextareaWithFloatedLabelProps>(
  function TextareaWithFloatedLabel({ label, value, onChange, required, disabled, error }, ref) {
    const [focused, setFocused] = useState(false)

    const floating = focused || !!value || undefined

    return (
      <Textarea
        ref={ref}
        autosize
        minRows={6}
        labelProps={{ 'data-floating': floating }}
        classNames={{
          root: classes.root,
          label: classes.label,
          input: classes.input,
          wrapper: classes.wrapper,
          error: classes.error,
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        data-floating={floating}
        label={label}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        error={error}
      />
    )
  },
)
